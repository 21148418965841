import type { Environment } from '@pcid/web-sdk';
import { createEvent, restore } from 'effector';
import { createContext, useContext } from 'react';

export const EnvironmentContext = createContext<EnvironmentContextParams | null>(null);

export const useEnvironment = () => {
	const context = useContext(EnvironmentContext);
	if (!context) {
		throw new Error('useEnvironment must be used within <EnvironmentContext.Provider>');
	}
	return context;
};

export type EnvironmentContextParams = Partial<{
	ClientId: string;
	ClientSecret: string;
	CustomerAPIEndpointUrl: string;
	DeploymentEnvironment: Environment;
	MidtierBookingManagementBaseUrl: string;
	MidtierProfileBaseUrl: string;
	MyAccountPcidUrl: string;
	NextAuthUrl: string;
	isSnowplowEnabled: boolean;
	GOOGLE_MAPS_API_KEY: string;
}>;

export const setEnvironment = createEvent<EnvironmentContextParams>();
export const $environment = restore(setEnvironment, {});
